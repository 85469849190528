
.ql-toolbar.ql-snow + .ql-container.ql-snow{
    font-family: 'Noto Sans Armenian', sans-serif;
    border: 0;
    font-size: 14px;
}
.ql-editor{
    padding: 0!important;
}
.article-read-more-content{
   padding: 15px;
    width: 100vw;
    height: 100dvh;
}

/*CONTAINER*/

section.article-read-more{
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    display: grid;
    grid-template-rows: minmax(max-content,110px) 1fr;
    background: #E1E4E8;
    border-radius: 20px;
    /*padding: 10px;*/
}

/*HEADER*/

.article-read-more__header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding: 20px 20px;*/
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 20px;
}
.article-read-more-header__close{
    display: flex;
    justify-content: flex-end;
    height: 100%;
    margin: 0px 10px;
}

.screenshot-btn-article button,
.screenshot-btn-article img {
    width: 18px;
    height: 27px;
}

.screenshot-btn-article div {
    height: auto;
    margin: 3px 25px;
}

.raed__more__font {
    align-self: flex-start;
    margin: 2px 5px 0 0;
}

.raed__more__font svg {
    width: 23px !important;
    height: 23px !important;
}

.article-read-more-header__close>span{
    height: max-content;
    width: max-content;
    cursor: pointer;
    margin-left: 40px;
}

.article-read-more-header__close svg{
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: .2s;
}
.article-read-more-header__close>span:hover svg{
    opacity: 90%;
}
.article-read-more-header__title{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.article-read-more-header__title>h2{
    /*display: flex;*/
    gap: 10px;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 47px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #101A29;
}
.article-read-more-header__title #like{
    width: 55px;
    height: 55px;
    cursor: pointer;
    display: inline-flex;
    translate: 15px 30%;
}
.article-read-more-header__title #like label{
    width: 55px;
    height: 55px;
    background-color: #FFFFFF;
    border-radius: 11px;
    scale: 0.9;
    box-shadow: 0px 0px 10px 0px #2d476c38;
}
/*BODY*/

.article-read-more__body{
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 80px;
    overflow: hidden auto;
    padding: 30px 30px 30px 30px;
}
.article-read-more__body::-webkit-scrollbar {
    width: 5px;
    background-color: #AAAAAA;
}

/* Track */
.article-read-more__body::-webkit-scrollbar-track {
    z-index: 11 !important;
}

/* Handle */
.article-read-more__body::-webkit-scrollbar-thumb {
    background: #2c476c;
    background-clip: padding-box;
    border-radius: 21px;
}
/*RIGHT BAR*/
.article-read-more-body__right-bar{
    background: #FBFDFF;
    border-radius: 11px;
    padding: 30px;
    display: grid;
    gap: 50px;
    grid-auto-rows: max-content;
    max-width: 500px;
    grid-template-columns: 100%;
    place-self: baseline;
    width: 100%;
}
.article-read-more-body__main{
    display: grid;
    gap: 50px;
    grid-auto-rows: max-content;
}
/*COLLAPS*/
.article-read-more__body .Collapsible{
    background: #FBFDFF;
    border: 1px solid rgba(44, 71, 108, 0.1);
    border-radius: 11px;
    position: relative;
}
.article-read-more__body .Collapsible .Collapsible__trigger{
    position: relative;
    z-index: 2;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.article-read-more__body .Collapsible .collaps-trigger-section{
    width: 100%;
    height: 50px;
    background: #2C476C;
    border-radius: 11px 11px 0px 0px;
    left: 0;
    top: 0;
    z-index: 1;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.article-read-more__body .Collapsible .collaps-trigger-section>span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.article-read-more__body .Collapsible .collaps-trigger-section svg{
    width: 20px;
    rotate: 90deg;
    transition: .5s;
    fill: white;
}
.article-read-more__body .Collapsible .collaps-trigger-section svg * {
    stroke: white;
}
.article-read-more__body .Collapsible .is-closed .collaps-trigger-section svg {
    rotate: -90deg;
}
.article-read-more-body__right-bar .Collapsible__contentOuter{
    background: rgba(44, 71, 108, 0.05);
    border: 1px solid rgba(44, 71, 108, 0.3);
    border-radius: 0px 0px 5px 5px;
}
.article-read-more-body-main__collaps-content{
    min-height: 170px;
    padding: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: var(--inner-div-font-size);
    line-height: 1.42;
    letter-spacing: 1px;
    color: #101A29;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.article-read-more-body-main__collaps-content > .quill > .ql-toolbar.ql-snow {
    display: none;
}

.article-read-more-body-main__collaps-content div div {
    font-size: var(--inner-div-font-size);
}

.style_rightBarBtnBox__lyFUT div {
    font-size: 14px !important;
}
.style_filterbtn__6STF\+ {
    font-size: 14px !important;
}

.article-read-more-body-right-bar__header-section{
    display: grid;
    gap: 10px;
    grid-auto-rows: max-content;
}
.article-read-more-body-right-bar__featured-image{
    width: 100%;
    aspect-ratio: 16/9;
    border-radius: 5px;
    contain: content;
}
.article-read-more-body-right-bar__audio{
    position: relative;
    width: 100%;
    height: 50px;
}

.overlay-loader {
    display: flex;
    height: 90px;
    width: 90px;
    align-items: center;
    justify-content: center;
}
.overlay-div {
    position: relative;
    padding: 10px;
    z-index: 999;
    max-width: 350px;
    background: #fff;
    display: none;
}

.overlay-div::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #DBDBDB;
    z-index: -1;
}

.overlay-div::after {
    content: "";
    position: absolute;
    height: 19px;
    width: 19px;
    transform-origin: 0% 0%;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
    left: 35%;
    top: 89%;
    background: #fff;
    border-bottom: 1px solid #DBDBDB;
    border-right: 1px solid #DBDBDB;
}

.overlay-div img {
    height: 65px;
    width: 65px;
    border-radius: 50px;
}

.overlay-div div div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.linked-article-header {
    color: #6E6C7A;
    font-size: 14px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.overlay-div div div p {
    height: 71px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 17px;
    font-size: 14px;
    color: #6E6C7A;
    margin-left: 10px;
}

.ql-editor p a {
    position: relative;
}
.ql-editor p a:hover + .overlay-div {
    display: block;
}
.map-media{
    height: 700px;
}
.map-media iframe{
    border: 0;
}

.article-read-more-body-main__collaps-content a {
    color: #06c;
    text-decoration: underline;
}


@media screen and (max-width: 1024px) and (orientation: landscape) {
.article-read-more-body__right-bar {
    width: 100% !important;
    max-width: none;
    padding: 15px 0px;
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
.article-read-more-body__right-bar {
    width: 100% !important;
    max-width: none;
    padding: 15px 0px;
  }
}
