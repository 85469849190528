/*.custom-cursor-wrapper {*/
/*    position: fixed;*/
/*    position: -ms-device-fixed;*/
/*    user-select: none !important;*/
/*    pointer-events: none;*/
/*}*/

.eraser-cursor {
    position: fixed;
    position: -ms-device-fixed;
    width: 30px; /* Adjust the size of your cursor */
    height: 30px; /* Adjust the size of your cursor */
    border-radius: 50%;
    background-color: rgb(0,0,0, 0.5);
    user-select: none !important;
    pointer-events: none;
    transition: transform 50ms;
    display: none; /* Initially hidden */
    z-index: -2;
}

.drawer-cursor {
    position: fixed;
    position: -ms-device-fixed;
    width: 30px; /* Adjust the size of your cursor */
    height: 30px; /* Adjust the size of your cursor */
    border-radius: 50%;
    background: url("../../../../assets/imgs/PaintBar/draw.png") no-repeat;
    user-select: none !important;
    pointer-events: none;
    transition: transform 50ms;
    display: none; /* Initially hidden */
    z-index: -2;
}
