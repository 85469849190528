.textBox-container {
    width: 100%;
    height: 100%;
    transition: ease-in-out 0.1s;
    position: relative;
}

.textBox {
    position: absolute;
}

.custom-popup {
    transform: translateX(-50%); /* Moves the popup to the left by half of its width */
    left: 0; /* Set the left position to 0 */
}

.color-picker-popup {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 220px;
    height: 130px;
    bottom: calc(100% + 5px);
    left: 0;
    background-color: #3064ab;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
}

.color-picker-popup-colors {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    cursor: pointer;
}

.textBox-container > .text_box_tools_color_item_container {
    padding: 10px 12px;
    background: #fff;
    position: absolute;
    width: 216px;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    z-index: 5;
    top: -220px;
    right: calc((100% - 540px));
    box-shadow: 0 4px 24px 0 #292E3233;
}

.textBox-container > .comment_box_tools_color_item_container > span {
    width: 22px;
    height: 22px;
    position: absolute;
    transform: translate(-50%, -50%) rotate(45deg);
    left: 89%;
    top: 98%;
    background: #fff;
}

.textBox-container > .edit-popup-header {
    width: 100%;
    position: absolute;
    top: -35px
}

.textBox-container > .edit-popup-header > .popUp-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    border-radius: 8px;
    background-color: #3064ab;
}

.textBox-container > .popUp-editor {
    border-radius: 8px;
    background-color: #fff;
    margin-top: 5px;
    box-shadow: 0px 7px 24px 0px #292E3233;
    min-height: 100%;
}

.textBox-container > .popUp-editor > div {
    width: 100%;
    font-family: "Noto Sans Armenian", "sans-serif";
    height: 100%;
    line-height: 1.15rem;
    font-size: 14px;
    border: none;
    outline: none;
    border-radius: 8px;
    resize: none;
    padding: 8px;
    background-color: transparent;
}

.comment-box-popup-container_color-picker > .current-picked-color-comment-box {
    display: flex;
    width: 35px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    justify-content: center;
}

.comment-box-popup-container_color-picker > .current-picked-color-comment-box > svg {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
}

.color-and-opacity-picker-popup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    position: absolute;
    width: 320px;
    padding: 10px;
    height: 110px;
    background-color: #2c476c;
    border-radius: 10px;
    box-shadow: 0px 4px 24px 0px #292E3233;
}

.color-and-opacity-picker-popup > .colors-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.fill-stroke-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.fill-stroke-button {
    width: 48%;
    height: 27px;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #fff;
    color: #5b5b5b;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.fill-stroke-button.active {
    background-color: #24B263;
    color: #fff;
}

.opacity-selector {
    width: 100%;
}

.opacity-selector > .range-container {
    position: relative;
    display: flex;
    height: 22px;
    width: 100%;
    background-color: #fff;
    border-radius: 20px;
}

.opacity-selector > .range-container > .background, .opacity-selector > .range-container > .checkerboard {
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; /* Makes sure the div does not interfere with the input */
    cursor: pointer;
}

.opacity-selector > .range-container > .background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
}

.opacity-selector > .range-container > .checkerboard {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image:
            linear-gradient(45deg, #ccc 25%, transparent 25%),
            linear-gradient(-45deg, #ccc 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, #ccc 75%),
            linear-gradient(-45deg, transparent 75%, #ccc 75%);
    background-size: 14px 14px; /* Size of each square */
    background-position: 0 0, 0 7px, 7px -7px, -7px 0px;
    z-index: 1;
}

.custom-slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%; /* Full width */
    height: 100%; /* Adjust height as needed */
    background: transparent; /* Hide the track */
    position: relative;
    z-index: 3;
    cursor: pointer;
}

/* Firefox */
.custom-slider::-moz-range-thumb {
    width: 14px; /* Thumb width */
    height: 14px; /* Thumb height */
    background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="white"/></svg>') no-repeat center;
    background-size: cover;
    cursor: pointer;
}

/* IE and Edge */
.custom-slider::-ms-thumb {
    width: 14px; /* Thumb width */
    height: 14px; /* Thumb height */
    background: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.285 17.57C15.2038 17.57 17.57 15.2038 17.57 12.285C17.57 9.36617 15.2038 7 12.285 7C9.36617 7 7 9.36617 7 12.285C7 15.2038 9.36617 17.57 12.285 17.57Z" fill="white"/></svg>') no-repeat center;
    background-size: cover;
    cursor: pointer;
}

.available-color, .painter_tools_color_data_item {
    width: 28px;
    height: 28px;
}

.font-dropdown-content {
    position: absolute;
    background-color: #ffffff;
    width: 45px;
    top: -5px;
    left: 45px;
    max-height: 100px;
    overflow-y: auto;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 0 0 7px 10px;
}

.font-dropdown-content span {
    color: #6E6B7B;
    padding: 2px 10px;
    text-decoration: none;
    display: block;
    font-size: 11px;
}

.font-dropdown-content span:hover {
    background-color: #f1f1f1;
}

.font-dropdown-content span.selected {
    font-weight: bold;
    background-color: #f1f1f1
}

.font-dropdown-content::-webkit-resizer {
    display: none;
}

.font-dropdown-content::-webkit-scrollbar {
    width: 5px;
    padding: 0px 2px;
}

.font-dropdown-content::-webkit-scrollbar-track {
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.font-dropdown-content::-webkit-scrollbar-thumb {
    width: 7px !important;
    background-color: #2c476c;
    outline: none;
    border-radius: 5px;
}

.font-size-picker {
    width: 45px;
    border-right: 2px solid #FFFFFF1F;
    border-left: 2px solid #FFFFFF1F;
    display: flex;
    justify-content: center;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}

.font-styles {
    margin: 0 5px;
    cursor: pointer;
}

.selected-style img {
    filter: hue-rotate(90deg) saturate(2) brightness(3)
}

.rotate-handle-holder .rotate-handle {
    width: 10px;
    height: 10px;
    top: -15px;
    left: -8px;
    border: 3px solid #559CFD;
    border-radius: 100%;
    cursor: url('/src/assets/imgs/PaintBar/rotate.svg'), auto;
}

.rotate-handle-holder {
    border: none;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 100%;
    margin-left: 100%;
}

.popUp-editor-false > .quill > .ql-toolbar.ql-snow {
    position: absolute;
    border: none;
    top: -40px;
    left: 85px;
    display: none;
}

.popUp-editor-true > .quill > .ql-toolbar.ql-snow {
    position: absolute;
    border: none;
    top: -40px;
    left: 85px;
}

.textBox-editor {
    background-color: red;
    width: 150px;
    height: 100px;
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
    fill: none;
    stroke: #fff !important;
}

.ql-active .ql-stroke {
    fill: none;
    stroke: #fff !important;
}

.ql-stroke {
    fill: none;
    stroke: #dbdbdb !important;
}

.ql-fill {
    fill: #dbdbdb !important;
    stroke: none;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
    fill: #fff !important;
    stroke: none;
}

.ql-active .ql-fill {
    fill: #fff !important;
    stroke: none;
}
