.draggable__div__box{
    animation: zoom-in-zoom-out 0.5s ease-out forwards;
    animation-fill-mode: forwards;
}
.draggable__div__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 12px 0;
    align-items: center;
}

.drag__icon {
    width: 25px;
    height: 25px;
    cursor: grab;
}

.draggable__div__header button {
    width: 15px;
    height: 15px;
    margin-left: 8px;
}

.slide__title {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0 8px -3px;
}

.slide__title p{
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.slide__title img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
}

.main__info {
    padding: 0 16px;
    display: grid;
    flex-direction: column;
    grid-template-rows: max-content 1fr;
    padding-bottom: 16px;
}

.slide__main__info {
    border-radius: 15px;
    border: 1px solid #EDEDED;
    background: #FFF;
    overflow-y: scroll;
    color: #6E6B7B;
    font-family: Mardoto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 8px;
    box-sizing: border-box;
}

.slide__main__info::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #314769;
}

.slide__main__info::-webkit-scrollbar {
    width: 5px;
}

.slide__main__info::-webkit-scrollbar-track{
    border-radius: 15px;
    margin: 10px;
}

.position__popup {
    position: absolute;
    top: 30px;
    right: 30px;
    background: #FFFFFF;
    box-shadow: 1px 2px 3px 3px rgba(44, 71, 108, 0.07);
    border-radius: 3px;
    padding: 4px 5px;
    align-items: center;
}
.position__popup button {
    margin: 0 3px
}

@keyframes modalSlide {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    75% {
        transform: scale(0.8, 0.8);
    }
    100% {
        transform: scale(1, 1);
    }
}

@media screen and (max-height: 1000px) {
    .slide__main__info {
        max-height: 56vh;
    }
}
